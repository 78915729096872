import { ADMINISTRATION_API_URL as API_URL } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { IMetadata } from '@/_types';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonAPIService {
  constructor(private readonly http: NovatiqHttpClient) {}

  public getGeographies() {
    return this.http.get(API_URL.COUNTRIES);
  }

  public getAllCategories() {
    return this.http.get(API_URL.CATEGORIES_ALL);
  }

  public getCampaignCategories(countryISO: string) {
    return this.http.get(API_URL.CAMPAIGN_CATEGORIES + '/' + countryISO);
  }

  public getClientByDataPartner(params) {
    return this.http.get(API_URL.CLIENT, params);
  }

  public getSisenseDashbord() {
    return this.http.get(API_URL.SISENSE_DASHBOARD);
  }

  public getSisenseDetails(functionName) {
    let params = new HttpParams();
    params = params.append(API_URL.FUNCTION_NAME, functionName);
    return this.http.get(API_URL.SIGNIN, params);
  }

  public getQuicksightDetails(functionName) {
    let params = new HttpParams();
    params = params.append(API_URL.FUNCTION_NAME, functionName);
    return this.http.get(API_URL.QUICKSIGHT_DASHBOARD, params);
  }

  public getBuySidesAndPublishers() {
    return this.http.get(API_URL.BUY_SIDES);
  }

  public getMetadata(mc_for: string, mc_name: string): Observable<IMetadata> {
    //metadata?for=Organisation&name=Organisation%20Type
    let params = new HttpParams();
    params = params.append('for', mc_for);
    params = params.append('name', mc_name);
    return this.http.get(API_URL.METADATA, params);
  }

  public putMetadata(data: any) {
    return this.http.put(API_URL.METADATA, data);
  }

  public getAuditLogs(tags: string) {
    let params = new HttpParams();
    params = params.append('tags', tags);
    return this.http.get(API_URL.AUDIT_LOG, params);
  }
}
