import { AUTHORIZATION_API_URL as API_URL, AUTHORIZATION_CLIENT } from '@/_contants';
import { NovatiqHttpClient } from '@/_http-client';
import { AuthorizationClientModel } from '@/_types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@codeandweb/ngx-translate';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private sessionLanguageSubject = new BehaviorSubject<string>(null);
  sessionLanguage$ = this.sessionLanguageSubject.asObservable();
  private languagesSubject = new BehaviorSubject<ILanguage[]>([]); // For available languages
  languages$ = this.languagesSubject.asObservable(); // Observable for the languages
  private readonly DEFAULT_LANGUAGE = 'en'; // Define a constant for default

  constructor(
    private readonly novatiqHttp: NovatiqHttpClient,
    public translate: TranslateService,
    private primengConfig: PrimeNGConfig
  ) {
    const storedSessionLanguage = sessionStorage.getItem(AUTHORIZATION_CLIENT.LOCALE);
    const defaultLanguage = this.getDefaultlocale();

    // Check if languages are valid once and store results
    const isSessionLangValid = this.isValidLanguage(storedSessionLanguage);
    const isDefaultLangValid = this.isValidLanguage(defaultLanguage);

    // Determine the initial language using concise logic
    const initialSessionLanguage = isSessionLangValid
      ? storedSessionLanguage
      : isDefaultLangValid
        ? defaultLanguage
        : this.DEFAULT_LANGUAGE;

    this.sessionLanguageSubject.next(initialSessionLanguage);
    this.initializeLanguages(); // Fetch and initialize languages
  }

  private initializeLanguages(): void {
    this.getLanguages('active').subscribe({
      next: (languages: ILanguage[]) => {
        this.languagesSubject.next(languages); // Update the languages subject
        const isos = languages.map((locale) => locale.iso);
        this.addSupportedLanguages(isos);
      },
      error: (error) => {
        this.addSupportedLanguages([this.DEFAULT_LANGUAGE]);
        console.error(error?.message);
      }
    });
  }

  getLanguages(type: 'active' | 'all'): Observable<ILanguage[]> {
    let URL = API_URL.LANGUAGES;
    if (type === 'active') {
      URL = `${URL}?active=true`;
    }
    return this.novatiqHttp.get(URL);
  }

  saveLanguage(selectedLanguage: string): Observable<any> {
    return this.novatiqHttp.put(`user`, { language: selectedLanguage });
  }

  initLanguage() {
    const storedSessionLanguage = sessionStorage.getItem(AUTHORIZATION_CLIENT.LOCALE);
    const defaultLanguage = this.getDefaultlocale();

    // Check if languages are valid once and store results
    const isSessionLangValid = this.isValidLanguage(storedSessionLanguage);
    const isDefaultLangValid = this.isValidLanguage(defaultLanguage);

    // Determine the initial language using concise logic
    const initialLanguage = isSessionLangValid
      ? storedSessionLanguage
      : isDefaultLangValid
        ? defaultLanguage
        : this.DEFAULT_LANGUAGE;

    this.translate.setDefaultLang(this.DEFAULT_LANGUAGE); // Always set default (fallback) to English
    this.useLanguage(initialLanguage);
  }

  // Function to validate if a language is valid
  isValidLanguage = (lang: string | null): lang is string => {
    if (lang) {
      try {
        // Attempt to create a new Locale with the provided string
        new Intl.Locale(lang);
        return true; // Language is valid
      } catch {
        return false; // Language is invalid
      }
    }
    return false; // Language is not valid (null, undefined, empty)
  };

  public addSupportedLanguages(languages: string[]) {
    this.translate.addLangs(languages);
  }

  public setSessionLanguage(language: string) {
    this.sessionLanguageSubject.next(language);
    sessionStorage.setItem(AUTHORIZATION_CLIENT.LOCALE, language);
  }

  public useLanguage(lang: string) {
    const locale = this.isValidLanguage(lang) ? lang : this.DEFAULT_LANGUAGE;
    // Fetch the translations for 'primeng' and unsubscribe automatically after success
    this.translate
      .get('primeng')
      .pipe(take(1))
      .subscribe((res) => {
        this.primengConfig.setTranslation(res);
      });

    // Load additional language files if the valid language is set
    this.loadAdditionalLanguageFiles(locale);
  }

  // Helper function to load additional language files
  private loadAdditionalLanguageFiles(locale: string) {
    this.getLocale(locale)
      .pipe(take(1))
      .subscribe({
        next: (translations) => {
          this.translate.setTranslation(locale, translations, true); // Merge translations
          this.translate.use(locale);
          const primengTranslations = translations?.primeng || {};
          this.primengConfig.setTranslation(primengTranslations);
        },
        error: (error) => {
          console.error(error?.message);
          this.translate.use(this.DEFAULT_LANGUAGE); //fallback to english if errored and translations not found.
        }
      });
  }

  /**
   *
   * @param locale iso
   * @returns translation json file from server
   */
  public getLocale(locale) {
    return this.novatiqHttp.get(`${API_URL.ASSETS_LANGUAGE}/${locale}.json`);
  }

  clearLocale() {
    this.sessionLanguageSubject.next(null); // Update the languages subject
    this.useLanguage(this.DEFAULT_LANGUAGE);
  }

  uploadTranslationFile(iso: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    ///assets/lang/{iso}
    return this.novatiqHttp.post(API_URL.ASSETS_LANGUAGE + '/' + iso, formData);
  }

  getDefaultlocale(): string {
    return this._getAuthenticatedUser()?.userInfo?.language;
  }
  private _getAuthenticatedUser(): AuthorizationClientModel {
    return JSON.parse(localStorage.getItem(AUTHORIZATION_CLIENT.SESSION_USER)) as AuthorizationClientModel;
  }

  getEmailTemplates(locale: string): Observable<IEmailTemplates> {
    return this.novatiqHttp.get(`${API_URL.EMAIL_TEMPLATES}/${locale}`);
  }

  putEmailTemplates(locale: string, formData): Observable<any> {
    return this.novatiqHttp.put(`${API_URL.EMAIL_TEMPLATES}/${locale}`, formData);
  }
}

export interface ILanguage {
  iso: string; // ISO code for the language
  name: string; // Name of the language
  default?: boolean;
  enabled: boolean;
}

export interface IEmailTemplate {
  key: string;
  subject: string;
  html: string;
  plaintext: string;
}

export interface IEmailTemplates {
  header: string;
  footer: string;
  templates: IEmailTemplate[];
  missingTemplates?: IEmailTemplate[];
}
