import { NovatiqAlertHelper, NovatiqPopupHelper } from '@/_helpers';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@codeandweb/ngx-translate';
import { Subscription, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-novatiq-popup',
  templateUrl: './novatiq-popup.component.html'
})
export class NovatiqPopupComponent implements OnInit, OnDestroy {
  @Input() hideBackground = true;
  @Input() modalsize = 'modal-md';
  @Input() defaultClose = true;
  @Input() theme = 'default';
  @Input() title = '';
  @Input() showFooter = false;
  @Input() id: string;
  @Output() closeModalPopup: EventEmitter<any> = new EventEmitter();

  showPopup: boolean = false;
  private getPopupSubscription: Subscription;

  constructor(
    private readonly alertHelper: NovatiqAlertHelper,
    private readonly popupHelper: NovatiqPopupHelper,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.initializeSubscriptions();
  }

  ngOnDestroy() {
    this.getPopupSubscription.unsubscribe();
  }

  close() {
    this.closeModalPopup.emit();
    this.popupHelper.close();
  }

  private initializeSubscriptions() {
    this.getPopupSubscription = this.popupHelper
      .getPopup()
      .pipe(
        switchMap((popupId) => {
          if (popupId) {
            return of(popupId);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (subscribedId) => {
          if (this.id === subscribedId) {
            this.showPopup = true;
          } else {
            this.showPopup = false;
          }
        },
        (error) => {
          this.alertHelper.error(error);
        }
      );
  }
}
