<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/img/brand/fusion_logo.png"
      alt="{{ 'fusion_platform' | translate }}"
      title="{{ 'fusion_platform' | translate }}"
      />
    </a>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav">
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" id="userDropdown" role="button" (click)="rightmenu.toggle($event)">
          <span class="mr-2 d-none d-lg-inline text-gray-400 medium">{{ userName }}</span>
          <p-avatar
            label="{{ userName | firstLetter | uppercase }}"
            [style]="{ 'background-color': 'var(--primary-purple)', 'color': '#ffffff' }"
            size="large"
            shape="circle"
          ></p-avatar>
        </a>
        <!-- Dropdown - User Information -->
        <!--<div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
        <a class="dropdown-item" href="javascript:void(0)"> <i class="pi pi-sign-out"></i> logout </a>
      </div>-->
    </li>
  </ul>
  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <!--<button
  #rightmenubutton
  class="p-link layout-rightmenu-button layout-topbar-button"
  (click)="rightmenu.toggle($event)"
  >
  <i class="pi pi-user"></i>
</button>-->
</div>

<p-menu appendTo="body" #rightmenu [model]="items" [popup]="true">
  <ng-template let-item pTemplate="item">
    @if (item.label === 'Language') {
      <div class="custom-item p-menuitem-link" (click)="$event.stopPropagation()">
        <span class="p-menuitem-icon {{ item.icon }}" tyle="font-size: 1.4rem"></span>
        <p-dropdown
          appendTo="body"
          [options]="languages"
          [(ngModel)]="sessionLanguage"
          optionLabel="endonym"
          optionValue="iso"
          placeholder="{{ 'language' | translate }}"
          (onChange)="onLanguageChange($event)"
          >
          <ng-template pTemplate="item" let-language>
            <span>{{ language.name }} ({{ language.endonym }}) @if (language.default) {
              <span>(default)</span>
            }</span>
          </ng-template>
          <ng-template pTemplate="selectedItem" let-language> {{ language.endonym }}</ng-template>
        </p-dropdown>
      </div>
    }
    @if (item.label !== 'Language') {
      <a [routerLink]="item?.routerLink" pRipple class="p-menuitem-link">
        <span class="p-menuitem-icon {{ item.icon }}"></span>
        <span class="p-menuitem-text">{{ item.label | translate }}</span>
      </a>
    }
  </ng-template>
</p-menu>
